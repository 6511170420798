<template>
  <div class="homePages">
    <div class="columns" style=" margin: 0;">
      <div class="column" style="padding:0;height:40px">
        <div class="is-flex-r is-a-c">
          <a-switch default-checked @change="onSwitch">
          <a-icon slot="checkedChildren" type="check" />
          <a-icon slot="unCheckedChildren" type="close" />
        </a-switch>
        <p v-if="showTable" class="is-m-l-1 p">显示图表</p>
        <p v-if="!showTable" class="is-m-l-1 p">显示表格</p>
        </div>
      </div>
      <div class="column is-4" style="padding:0;" v-if="showTable">
        <div class="is-flex-r is-j-e" style="height: 40px">
          <p class="p is-m-r-3">联系人：xxxxxx</p>
          <p class="p">电话：18742081234</p>
        </div>
      </div>
    </div>
    <div class="is-m-b-2" v-if="showTable">
      <a-card class="m-cards">
        <div class="columns">
          <div class="column is-7">
            <a-form
              :form="form"
              :label-col="{ span: 1 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-form-item label="公司/项目">
                <a-cascader
                  :options="companyList"
                  placeholder="请选择公司/项目"
                  @change="onChange"
                />
              </a-form-item>
            </a-form>
          </div>
          <div class="column is-1">
            <div class="cards-m">
              <img v-if="show" src="../../assets/images/wei.png" alt="" />
              <img v-else src="../../assets/images/yi.png" alt="" />
            </div>
          </div>
        </div>
      </a-card>
    </div>
    <div class="columns" v-if="showTable">
      <div class="column" v-for="item in homeNavTitle" :key="item.id">
        <a-card>
          <homeNavTitle :list="item"></homeNavTitle>
        </a-card>
      </div>
    </div>
    <div class="columns" v-if="showTable">
      <div class="column">
        <a-card>
          <barOne class="echart" />
        </a-card>
      </div>
      <div class="column">
        <a-card>
          <barTwo class="echart" />
        </a-card>
      </div>
    </div>
    <div v-else>
      <tableList class="is-p-2" />
    </div>
  </div>
</template>
<script>
import homeNavTitle from '@/components/home-nav-title'
import Vue from 'vue'
import tableList from './tableList'
export default {
  components: {
    homeNavTitle,
    tableList,
    barOne: Vue.extend(require('@/components/echarts/bar-one.vue').default),
    barTwo: Vue.extend(require('@/components/echarts/bar-two').default),
    // barRow: Vue.extend(require('@/components/echarts/bar-row1').default)
  },
  data() {
    return {
      showTable: true, //是否显示 表格
      show: false, //是否显示 培训状态
      form: {}, //搜索表单
      companyList: [],
      homeNavTitles: [ //状态框数据
        {
          title: '总人数',
          num: '5030',
          unit: '人',
          icon: 'home-zong',
          color: '#4389F9'
        },
      ],
      homeNavTitle: [ //状态框数据
        {
          title: '总人数',
          num: '5030',
          unit: '人',
          icon: 'home-zong',
          color: '#4389F9'
        },
        {
          title: '已培训次数',
          num: '442',
          unit: '次',
          icon: 'home-wan',
          color: '#5ae0fb'
        },
        {
          title: '待培训人数',
          num: '500',
          unit: '人',
          icon: 'home-ren',
          color: '#FFA632'
        },
        {
          title: '已培训人数',
          num: '1200',
          unit: '人',
          icon: 'home-ren',
          color: '#FF6999'
        },
        {
          title: '新增人数',
          num: '100',
          unit: '人',
          icon: 'home-zeng',
          color: '#7AF993'
        }
      ]
    }
  },
  mounted() {
    this.getProjectList()
  },
  methods: {
     getProjectList () {
      this.loading = true 
      this.api.getProjectList().then(res => {
        this.loading = false
        console.log('公司-项目',res.data.data)
        this.companyList = res.data.data
      }).catch(() => {
        this.loading = false
      })
    },
    onSwitch(checked) {
      console.log(`a-switch to ${checked}`);
      this.showTable = checked
    },
    onChange(value) {
      console.log(value);
    },
    handleChange(value) {
      console.log(`selected ${value}`);
    },
  }
}
</script>

<style lang="less">
.homePages {
  .p {
    margin-bottom: 0 !important;
    font-size: 16px;
  }
  .ant-form-item {
    margin: 0;
  }
  .ant-card-body {
    padding: 15px 24px;
    zoom: 1;
  }
  .echart {
    width: 100%;
    height: 400px;
  }
  .echart3 {
    width: 100%;
    height: 200px;
  }
  .m-cards {
    overflow: hidden;
  }
  .cards-m {
    position: absolute;
    right: -10px;
    top: -20px;
    width: 110px;
    height: 110px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
       