
<template>
  <div class="echarts">
    <div id="bar1"></div>
  </div>
</template>
 
<script>
import Echarts from 'echarts'
export default {
  data() {
    return {
      myChart: {}
    }
  },
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.loadEchart()
      })

    })
  },
  mounted() { },
  methods: {
    loadEchart() {
      this.myChart = Echarts.init(document.getElementById("bar1"));
      this.myChart.setOption({
        color: this.$store.state.color2,
        title: {
          text: '今日和昨日'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: this.$store.state.axisPointer,
          formatter: function (params) {
            if (params[0].data == 11) {
               return '进展 : 有培训'+ '<br>'+ params[1].seriesName + ' : ' + params[1].value
            } else if (params[0].data == 10) {
               return '进展 : 无培训'+ '<br>'+ params[1].seriesName + ' : ' + params[1].value
            }else{
               return params[1].seriesName + ' : ' + params[1].value
            }
          }
        },
        legend: {
          top: 'bottom'
        },
        grid: this.$store.state.grid,
        toolbox: {
          show: true,

        },
        xAxis: {
          type: 'category',
          inverse: true,
          data: ['今日', '昨日'],
          axisLabel: {
            textStyle: {
              color: "#000"
            },
            margin: 20,
            rich: {
              value: {
                lineHeight: 30,
                align: 'center'
              },
              Cloudy: {
                height: 40,
                align: 'center'
              },
              Showers: {
                height: 40,
                align: 'center'
              }
            }
          },
          splitLine: this.$store.state.splitLine,
          axisLine: this.$store.state.axisLine

        },
        yAxis: {
          type: 'value',
          name:'人数',
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: "#000"
            }
          },
          splitLine: this.$store.state.splitLine,
          axisLine: this.$store.state.axisLine
        },
        series: [
          {
            name: '进展',
            type: 'bar',
            data: [11, 10],
            markPoint: {
              symbolSize: 1,
              symbolOffset: [0, '50%'],
              label: {
                formatter: '{b|{b}}',
                backgroundColor: '#5AE0FB',
                color: '#fff',
                borderRadius: 100,
                padding: [20, 8],
                position: 'top',
                distance: 20,
                rich: {}
              },
              data: [
                { type: 'max', name: '有培训' },
                { type: 'min', name: '无培训' }
              ]
            }
          },
          {
            name: '新增培训人数',
            type: 'bar',
            data: [220, 82],
            label: {
              show: true,
              formatter: '{c}人',
              distance: 20,
              position: 'top',
              color: '#000',
              fontSize: 16,
              valueAnimation: true,
              fontFamily: 'monospace'
            }
          }
        ]
      }),
        window.addEventListener('resize', () => {
          this.myChart.resize()
        })
    }
  }
}
</script>
 
<style>
#bar1 {
  width: 100%;
  height: 100%;
}
</style>