
<template>
  <div class="echarts">
    <div id="bar2"></div>
  </div>
</template>
 
<script>
import Echarts from 'echarts'
export default {
  data() {
    return {
      myChart: {}
    }
  },
  created() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.loadEchart()
      })

    })
  },
  mounted() {},
  methods: {
    loadEchart() {
      this.myChart = Echarts.init(document.getElementById("bar2"));
      this.myChart.setOption({
        color: this.$store.state.color1,
        title: {
          text: '待培训人数和已培训人数',
          //   subtext: '纯属虚构'
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['待培训人数', '已培训人数'],
          top: 'bottom'
        },
        grid: this.$store.state.grid,
        toolbox: {
          show: false,
          feature: {
            dataView: { show: true, readOnly: false },
            magicType: { show: true, type: ['line', 'bar'] },
            restore: { show: true },
            saveAsImage: { show: true }
          }
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            axisLine: this.$store.state.axisLine,
            axisLabel: this.$store.state.axisLabel,
            data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
          }
        ],
        yAxis: [
          {
            type: 'value',
             name:'人数',
            axisLine: this.$store.state.axisLine,
            axisLabel: this.$store.state.axisLabel,
            splitLine: this.$store.state.splitLine
          }
        ],
        series: [
          {
            name: '待培训人数',
            type: 'bar',
            data: [2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 35.6, 62.2, 32.6, 20.0, 6.4, 3.3],
            markPoint: {
              data: [
                { type: 'max', name: '最大值' },
                { type: 'min', name: '最小值' }
              ]
            },
          },
          {
            name: '已培训人数',
            type: 'bar',
            data: [2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 75.6, 82.2, 48.7, 18.8, 6.0, 2.3],
            markPoint: {
              data: [
                { name: '年最高', value: 182.2, xAxis: 7, yAxis: 183 },
                { name: '年最低', value: 2.3, xAxis: 11, yAxis: 3 }
              ]
            },
          }
        ]
      }),
      window.addEventListener('resize',()=>{
         this.myChart.resize()
      })
    }
  }
}
</script>
 
<style>
#bar2 {
  width: 100%;
  height: 100%;
}
</style>