<template>
  <div class="homeNavTitle is-flex-c is-a-c">
    <svg-icon :iconClass="list.icon" :style="{color:list.color}" className="svgIcon" />
    <div class="is-f-b is-f-bc is-flex-r">
      <p class="is-f-7">{{list.num}}</p>
      <p class="is-f-3 is-m-t-2">{{list.unit}}</p>
    </div>
    <p class="is-f-1">{{list.title}}</p>
  </div>
</template>
<script>
export default {
   props: {
      list: {
        type: Object,
       default: () => {},
      }
    },
  data() {
    return {

    }
  }
}
</script>
<style lang="less">
.homeNavTitle {
  .svgIcon {
    width: 35px;
    height: 35px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>