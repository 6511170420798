<template>
  <div class="graphic">
    <!--搜索-->
    <a-card class="search-bar">
      <a-form-model ref="searchForm" :model="searchForm">
        <a-row :gutter="16">
          <a-col :span="5">
            <a-form-model-item label="公司名称" prop="name">
              <a-input
                v-model.trim="searchForm.name"
                allow-clear
                placeholder="选择"
                @keyup.native.enter="search()"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item label="联系人" prop="wt_id">
              <a-select
                v-model="searchForm.wt_id"
                allow-clear
                placeholder="选择"
              >
                <a-select-option v-for="level in levels" :key="level.kid">
                  {{ level.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="5">
            <a-form-model-item label="电话" prop="wt_id">
              <a-select
                v-model="searchForm.wt_id"
                allow-clear
                placeholder="选择"
              >
                <a-select-option v-for="level in levels" :key="level.kid">
                  {{ level.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="4">
            <a-form-model-item>
              <a-button :loading="loading" type="primary" @click="search()">
                查 询
              </a-button>
              <a-button @click="reset()">重 置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <!--表格-->
    <a-card class="graphic-table">
      <template slot="title">
        <span class="table-title">项目列表</span>
      </template>
      <a-table
        rowKey="kid"
        :scroll="{ x: 2500, y: 0 }"
        :loading="loading"
        :columns="columns"
        :data-source="datalist"
        :pagination="pagination"
        @change="onPageChange"
      >
      </a-table>
    </a-card>
  </div>
</template>

<script>
export default {
  name: "graphic",
  components: {},
  data() {
    return {
      //搜索字段
      searchForm: {
        name: "",
        wt_id: "",
      },
      //选择器-数据
      workTypes: [],
      //搜索 loading
      loading: false,
      //搜索-筛选
      levels: [],
      //table-数据
      datalist: [],
      //table-配置
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          align: 'center',
          width: 65,
          customRender: (text, record, index) => `${index + 1}`,
        },
        { title: "公司名称", key: "name", dataIndex: "name", ellipsis: true },
        {
          title: "目前状态",
          key: "sort",
          dataIndex: "sort",
          align: "center",
          ellipsis: true,
        },
        {
          title: "最近一次培训时间",
          key: "level",
          dataIndex: "level",
          align: "center",
          ellipsis: true,
        },
        {
          title: "已培训次数",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        },
        {
          title: "已培训人数",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        },
        {
          title: "待培训人数（在场）",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        },
        {
          title: "总人数",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        },
        {
          title: "新增人数",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        },
        {
          title: "昨日进展",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        },
        {
          title: "昨日新增培训人数",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        },
        {
          title: "今日计划",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        },
        {
          title: "今日新增培训人数",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        },
        {
          title: "培训情况",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        },
        {
          title: "联系人",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        },
        {
          title: "电话",
          key: "level_sub",
          dataIndex: "level_sub",
          align: "center",
          ellipsis: true,
        }
      ],
      //table-分页
      pagination: {
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "50"],
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total) => `共 ${total} 条`,
      }
    };
  },
  mounted() {
    //初始化数据
    this.getCourseList();
    //搜索-筛选
    this.allLevels();
  },
  methods: {
    //搜索
    search() {
      console.log(this.searchForm);
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.getCourseList();
    },
    //搜索-重置
    reset() {
      this.$refs.searchForm.resetFields();
    },
    //分页
    onPageChange(e) {
      this.pagination = e;
      this.searchForm.page = e.current;
      this.searchForm.list_rows = e.pageSize;
      this.getCourseList();
      console.log("分页", e);
    },
    del(e) {
      console.log("删除", e);
    },
    //请求
    getCourseList() {
      this.loading = true;
      this.api
        .getCourseList(this.searchForm)
        .then((res) => {
          console.log("res", res);
          this.datalist = res.data.data;
          this.pagination.total = res.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //搜索-筛选
    allLevels() {
      this.api.allLevels({ level_id: 1 }).then((res) => {
        this.levels = res.data.data;
      });
    }
  }
};
</script>

<style scoped lang="less"></style>
